import './styles.scss';
import React, {memo, useLayoutEffect, useEffect, useRef, useState} from 'react';

import { 
    Chart as ChartJS, 
    ArcElement, 
    Tooltip, 
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    ScriptableContext } from 'chart.js';
import { Doughnut, Line } from 'react-chartjs-2';
import CountUp from 'react-countup';
import { gsap, Power1 } from "gsap";

ChartJS.register(ArcElement, Tooltip);

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Filler,
    Tooltip,
);

import {
    traction1,
    traction2,
    traction3,
    traction4,
    traction5,
    traction6,
    traction7,
    traction8,
    paidArrow,
    ceoInsta,
    ceoLinked,
    ceoGmail,
    DRLinkedIn,
    DRGmail,
    DRInsta,
    DRCb,
    DRX,
    DRFb,
    DataRoomModalClose,
    DataRoomModalMail
} from "../../images";

type Props = {
    status: string | null
}

const DataRoomDash = ({status}: Props) => {

    const [modalStatus, setModalStatus] = useState(false);
    const [dataTab, setDataTab] = useState('dec');
    const rootEl = useRef(null);
    const timeLine = useRef();

    const [chartData, setChartData] = useState({
        labels: ['United State', 'United Kingdom', 'Australia', 'Canada'],
        datasets: [
            {
                label: '% of users',
                data: [70, 14, 8, 8],
                backgroundColor: [
                    '#FFC592',
                    '#FF8066',
                    '#00C9A7',
                    '#B181FF',
                ],
                borderWidth: 0,
                weight: 4,
                
            },
        ]
    });
    const [chartData2, setChartData2] = useState({
        labels: ['United State', 'United Kingdom', 'Australia', 'Canada'],
        datasets: [
            {
                label: '% of users',
                data: [72, 16, 6, 6],
                backgroundColor: [
                    '#FFC592',
                    '#FF8066',
                    '#00C9A7',
                    '#B181FF',
                ],
                borderWidth: 0,
                weight: 4,
                
            },
        ]
    });


    const options = {
        responsive: true,
        aspectRatio: 3,
        scales: {
            y: {
                border: {
                    color: '#1B1B1B',
                },
                ticks: {
                    stepSize: 5,
                    font: {
                        size: 10,
                    },
                    callback: function(value:any, index:any, ticks:any) {
                        return '$' + value + 'k';
                    }
                }
            },
            x: {
                border: {
                    color: '#1B1B1B',
                },
                ticks: {
                    font: {
                        size: 10,
                    },
                }
            }
        }
    };

    const [labels, setLabels] = useState([
        '', 
        'February', 
        '',
        '',
        '',
        '',
        'March',
        '',
        '',
        '',
        'April',
    ])

    const [lineData, setLineData] = useState({
        labels,
        datasets: [
            {
                fill: true,
                label: 'MRR',
                data: [0, 5, 5.7, 6.2, 7.4, 9, 10, 9.8, 10.3, 13, 20],
                borderColor: '#FFC592',
                backgroundColor: (context: ScriptableContext<"line">) => {
                    const ctx = context.chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
                    gradient.addColorStop(0, "rgba(147, 97, 54, 0.6)");
                    gradient.addColorStop(1, "rgba(255, 151, 129, 0)");
                    return gradient;
                },
                pointBackgroundColor: '#fff',
                pointBorderColor: '#FFC592',
                pointBorderWidth: 2,
                borderWidth: 2,
            },
        ],
    });

    const gsapFunc = () => {
        const ctx = gsap.context(() => {
            // timeLine.current && timeLine.current.progress(0).kill();
            // timeLine.current = gsap.timeline()
            
            setTimeout(() => {
                gsap.fromTo(".anime-stats", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });
                gsap.fromTo(".anime-stats", {
                    scale: "2",
                    y: "50%"
                }, {
                    scale: "1",
                    y: "0%",
                    duration: 1.50,
                    ease: Power1.easeIn,
                });
            }, 250); 
            
            setTimeout(() => {
                gsap.fromTo(".anime-right-top", {
                    scale: "2",
                    x: "250%",
                    y: "-100%"
                }, {
                    scale: "1",
                    x: "0%",
                    y: "0%",
                    duration: 1.25,
                    ease: Power1.easeIn,
                })
                gsap.fromTo(".anime-right-top", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });

                gsap.fromTo(".anime-right-bottom", {
                    scale: "2",
                    x: "250%",
                    y: "100%"
                }, {
                    scale: "1",
                    x: "0%",
                    y: "0%",
                    duration: 1.25,
                    ease: Power1.easeIn,
                })
                gsap.fromTo(".anime-right-bottom", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });

                gsap.fromTo(".anime-bottom", {
                    scale: "2",
                    x: "0%",
                    y: "250%"
                }, {
                    scale: "1",
                    x: "0%",
                    y: "0%",
                    duration: 1.25,
                    ease: Power1.easeIn,
                })
                gsap.fromTo(".anime-bottom", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });

                gsap.fromTo(".anime-left-bottom", {
                    scale: "2",
                    x: "-250%",
                    y: "100%"
                }, {
                    scale: "1",
                    x: "0%",
                    y: "0%",
                    duration: 1.25,
                    ease: Power1.easeIn,
                })
                gsap.fromTo(".anime-left-bottom", {
                    opacity: "0"
                }, {
                    opacity: "1",
                    duration: 0.41,
                    ease: Power1.easeIn,
                });
            }, 500)
            
        }, rootEl);
    }

    useEffect(() => {
        gsapFunc();
    }, [status]);

    // useEffect(() => {
    //     gsapFunc();
    // }, []);

    return (
        <section className="dataRoomDash" ref={rootEl}>
            <div className="dataRoomContainer">
                <div className="dataRoomDashWrap">
                    <div className="dataRoomDashMain">
                        <div className="dataRoomDashRow">
                            <div className="dataRoomDashBigcol anime-stats">
                                <div className="dataRoomDashHeading">
                                    <span className="dataRoomDashTitle">Traction</span>
                                    {/* <div className="dataRoomDashTabs">
                                        <span className="dataRoomDashTab active">Finances</span>
                                        <span className="dataRoomDashTab">Marketing</span>
                                        <span className="dataRoomDashTab">Retention</span>
                                    </div> */}
                                    <div className="dataRoomDashTabs">
                                        <span className={`dataRoomDashTab ${(dataTab === 'nov') ? 'active' : ''}`} onClick={() => setDataTab('nov')}>November Report</span>
                                        <span className={`dataRoomDashTab ${(dataTab === 'dec') ? 'active' : ''}`} onClick={() => setDataTab('dec')}>December Report</span>
                                    </div>
                                </div>
                                
                                {
                                    (dataTab === 'nov') ? (
                                        <div className="dataRoomDashStats">
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $<CountUp end={18207} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">Revenue</span>
                                                        Monthly Recurring Revenue
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $<CountUp end={10215} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">MRR</span>
                                                        Monthly Recurring Revenue
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $2,<CountUp end={96} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">ARPU</span>
                                                        Average Revenue Per User
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat mob100">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $<CountUp end={28500} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">Advertising Budget</span>
                                                        Targeted Advertising Budget
                                                    </p>
                                                </div>
                                            </div>
                                            
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound purple"></div>
                                                <img src={traction2} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        <CountUp end={700} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="purple">Paying users</span>
                                                        In real time
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound green"></div>
                                                <img src={traction3} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        <CountUp end={5500} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="green">MAU</span>
                                                        Monthly active users
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound blue"></div>
                                                <img src={traction4} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $<CountUp end={35000} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="blue">Burn rate</span>
                                                        Monthly
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="dataRoomDashStat mob100">
                                                <div className="dataRoomDashStatRound blue"></div>
                                                <img src={traction4} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $44,<CountUp end={11} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="blue">CAC</span>
                                                        Customer Acquisition Cost
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound blue2"></div>
                                                <img src={traction5} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        <CountUp end={6148} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="blue2">Installs</span>
                                                        During November
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $<CountUp end={120066} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">ARR</span>
                                                        Annual Recurring Revenue
                                                    </p>
                                                </div>
                                            </div> */}
                                            
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $23,<CountUp end={80} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">ARPPU</span>
                                                        Average Revenue Per Paying User
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound gold2"></div>
                                                <img src={traction6} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        <CountUp end={732} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold2">New subscriptions</span>
                                                        During November
                                                    </p>
                                                </div>
                                            </div> */}
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound purple2"></div>
                                                <img src={traction7} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        <CountUp end={762} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="purple2">Active subscriptions</span>
                                                        As of November 30th
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound purple3"></div>
                                                <img src={traction8} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                    11.<CountUp end={91} duration={4} />%
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="purple3">Conversion rate</span>
                                                        from install to paid
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="dataRoomDashStats">
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $<CountUp end={22000} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">Revenue</span>
                                                        Monthly Total Revenue
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $<CountUp end={12500} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">MRR</span>
                                                        Monthly Recurring Revenue
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $2,<CountUp end={90} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">ARPU </span>
                                                        Average Revenue Per User 
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat mob100">
                                                <div className="dataRoomDashStatRound gold"></div>
                                                <img src={traction1} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $<CountUp end={19985} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="gold">Advertising Budget </span>
                                                        Targeted Advertising Budget
                                                    </p>
                                                </div>
                                            </div>

                                            {/* row 2 */}
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound purple"></div>
                                                <img src={traction2} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        <CountUp end={1040} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="purple">Paying users</span>
                                                        In real time
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat">
                                                <div className="dataRoomDashStatRound green"></div>
                                                <img src={traction3} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        <CountUp end={8240} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="green">MAU</span>
                                                        Monthly active users
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat mob100">
                                                <div className="dataRoomDashStatRound blue"></div>
                                                <img src={traction4} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $<CountUp end={27385} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="blue">Burn rate</span>
                                                        Monthly (Including Marketing Budget)
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashStat mob100">
                                                <div className="dataRoomDashStatRound blue"></div>
                                                <img src={traction4} className="dataRoomDashStatIcon" />
                                                <div className="dataRoomDashStatInside">
                                                    <span className="dataRoomDashStatValue">
                                                        $35,<CountUp end={49} duration={4} />
                                                    </span>
                                                    <p className="dataRoomDashStatText">
                                                        <span className="blue">CAC</span>
                                                        Customer Acquisition Cost
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                        
                        {
                            (dataTab === 'nov') ? (
                                <div className="dataRoomDashRow">
                                    <div className="dataRoomDashSmallcol2 anime-left-bottom">
                                        <span className="dataRoomChartTitle">Users</span>
                                        <div className="dataRoomCircleChartBox">
                                            <div className="dataRoomCircleChart">
                                                <span className="dataRoomCircleChartNumber">18.700</span>
                                                <Doughnut 
                                                    data={chartData}
                                                    options={{
                                                        cutout: '80%'
                                                    }} 
                                                    />
                                            </div>
                                            <div className="dataRoomCircleChartLabels">
                                                <span className="dataRoomCircleChartLabel color1">United States</span>
                                                <span className="dataRoomCircleChartLabel color2">United Kingdom</span>
                                                <span className="dataRoomCircleChartLabel color3">Australia</span>
                                                <span className="dataRoomCircleChartLabel color4">Canada</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dataRoomDashMediumcol anime-bottom">
                                        <span className="dataRoomChartTitle">Retention Adapty Report (Weekly Product) </span>
                                        <div className="dataRoomDashTable">
                                            <div className="dataRoomDashTableRow">
                                                <div className="dataRoomDashTableCol big">
                                                    <span>Paid</span>
                                                    <img src={paidArrow} alt="" />
                                                    <span>2nd period</span>
                                                </div>
                                                <div className="dataRoomDashTableCol">
                                                    <span>68.29%</span>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashTableRow">
                                                <div className="dataRoomDashTableCol big">
                                                    <span>2nd</span>
                                                    <img src={paidArrow} alt="" />
                                                    <span>3rd period</span>
                                                </div>
                                                <div className="dataRoomDashTableCol">
                                                    <span>81.07%</span>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashTableRow">
                                                <div className="dataRoomDashTableCol big">
                                                    <span>3rd</span>
                                                    <img src={paidArrow} alt="" />
                                                    <span>4th period</span>
                                                </div>
                                                <div className="dataRoomDashTableCol">
                                                    <span>83.70%</span>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashTableRow">
                                                <div className="dataRoomDashTableCol big">
                                                    <span>4th</span>
                                                    <img src={paidArrow} alt="" />
                                                    <span>5th period</span>
                                                </div>
                                                <div className="dataRoomDashTableCol">
                                                    <span>87.23%</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="dataRoomColChartScroll">
                                            <div className="dataRoomColChart">
                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$2 000</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "30%"}}>
                                                            <span className="dataRoomColChartValue">July</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$2 000</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "30%"}}>
                                                            <span className="dataRoomColChartValue">August</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$2 000</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "30%"}}>
                                                            <span className="dataRoomColChartValue">September</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$2 000</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "30%"}}>
                                                            <span className="dataRoomColChartValue">October</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$18 700</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "80%"}}>
                                                            <span className="dataRoomColChartValue">November</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                        {/* <div className="dataRoomLineChart">
                                            <Line data={lineData} options={options} />
                                        </div> */}
                                    </div>
                                </div>
                            ) : (
                                <div className="dataRoomDashRow">
                                    <div className="dataRoomDashSmallcol2 anime-left-bottom">
                                        <span className="dataRoomChartTitle">Users</span>
                                        <div className="dataRoomCircleChartBox">
                                            <div className="dataRoomCircleChart">
                                                <span className="dataRoomCircleChartNumber">22.100</span>
                                                <Doughnut 
                                                    data={chartData2}
                                                    options={{
                                                        cutout: '80%'
                                                    }} 
                                                    />
                                            </div>
                                            <div className="dataRoomCircleChartLabels">
                                                <span className="dataRoomCircleChartLabel color1">United States</span>
                                                <span className="dataRoomCircleChartLabel color2">United Kingdom</span>
                                                <span className="dataRoomCircleChartLabel color3">Australia</span>
                                                <span className="dataRoomCircleChartLabel color4">Canada</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="dataRoomDashMediumcol anime-bottom">
                                        <span className="dataRoomChartTitle">FeelGrow - Ad Performance (Excluding Commission) </span>
                                        <div className="dataRoomDashTable">
                                            <div className="dataRoomDashTableRow">
                                                <div className="dataRoomDashTableCol big">
                                                    <span>Instant</span>
                                                    <img src={paidArrow} alt="" />
                                                    <span>ROMI Fact</span>
                                                </div>
                                                <div className="dataRoomDashTableCol">
                                                    <span>+10.11%</span>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashTableRow">
                                                <div className="dataRoomDashTableCol big">
                                                    <span>ROMI</span>
                                                    <img src={paidArrow} alt="" />
                                                    <span>Predictive 3M</span>
                                                </div>
                                                <div className="dataRoomDashTableCol">
                                                    <span>+34.27%</span>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashTableRow">
                                                <div className="dataRoomDashTableCol big">
                                                    <span>ROMI</span>
                                                    <img src={paidArrow} alt="" />
                                                    <span>Predictive 6M</span>
                                                </div>
                                                <div className="dataRoomDashTableCol">
                                                    <span>+49.16%</span>
                                                </div>
                                            </div>
                                            <div className="dataRoomDashTableRow">
                                                <div className="dataRoomDashTableCol big">
                                                    <span>ROMI</span>
                                                    <img src={paidArrow} alt="" />
                                                    <span>Predictive 12M</span>
                                                </div>
                                                <div className="dataRoomDashTableCol">
                                                    <span>+76.46%</span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="dataRoomColChartScroll">
                                            <div className="dataRoomColChart">
                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$2 000</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "30%"}}>
                                                            <span className="dataRoomColChartValue">July</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$2 000</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "30%"}}>
                                                            <span className="dataRoomColChartValue">August</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$2 000</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "30%"}}>
                                                            <span className="dataRoomColChartValue">September</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$2 000</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "30%"}}>
                                                            <span className="dataRoomColChartValue">October</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="dataRoomColChartItem">
                                                    <p className="dataRoomColChartTitle">$18 700</p>
                                                    <div className="dataRoomColChartBody">
                                                        <div className="dataRoomColChartLevel" style={{"height": "80%"}}>
                                                            <span className="dataRoomColChartValue">November</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                        {/* <div className="dataRoomLineChart">
                                            <Line data={lineData} options={options} />
                                        </div> */}
                                    </div>
                                </div>
                            )
                        }

                    </div>
                    <div className="dataRoomDashSide">
                        <div className="dataRoomDashSmallcol flex anime-right-top">
                            <div className="dataRoomFunds">
                                <div className="dataRoomFundsHead">
                                    <span>Confirmed funding</span>
                                    <p>Pre Seed Round</p>
                                </div>
                                <div className="dataRoomFundsProgress">
                                    <div className="dataRoomFundsInfo">
                                        <span>$0</span>
                                        <span>$300 000</span>
                                    </div>
                                    <div className="dataRoomFundsProgressBar">
                                        <div className="dataRoomFundsProgressLine" ></div>
                                    </div>
                                </div>
                                <a href="mailto:ceo@feelgrow.ai" className="dataRoomFundsMore">Lear more</a>
                            </div>
                            {/* <a href="mailto:ceo@feelgrow.ai" className="dataRoomAsk">
                                <span className="dataRoomAskTitle">Ask us a question?</span>
                                <p className="dataRoomAskText">If you have a question</p>
                                <div className="dataRoomAskIcon">
                                    <img src={questionIcon} alt="" />
                                </div>
                            </a> */}
                            <div className="dataRoomSocials">
                                <p className="dataRoomSocialsTitle">Find FeelGrow here:</p>
                                <div className="dataRoomSocialsBox">
                                    <a href="https://www.linkedin.com/company/aeer-platform/">
                                        <img src={DRLinkedIn} alt="" />
                                    </a>
                                    <a href="mailto:ceo@feelgrow.ai">
                                        <img src={DRGmail} alt="" />
                                    </a>
                                    <a href="https://www.instagram.com/feelgrow.app/">
                                        <img src={DRInsta} alt="" />
                                    </a>
                                    <a href="https://www.crunchbase.com/organization/aeer-platform-inc">
                                        <img src={DRCb} alt="" />
                                    </a>
                                    <a href="https://twitter.com/aeer_platform?s=21&t=N7bkWJkRyTtAGzOJxm0EMw">
                                        <img src={DRX} alt="" />
                                    </a>
                                    <a href="https://www.facebook.com/aeer.platform">
                                        <img src={DRFb} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="dataRoomDashSmallcol bg pa24 anime-right-bottom">
                            <div className="dataRoomDashCEO">
                                <div className="dataRoomDashCEOInfo">
                                    <span className="dataRoomDashCEOTitle">Andrew Filatov</span>
                                    <p className="dataRoomDashCEOPosition">Founder and CEO</p>
                                    <p className="dataRoomDashCEOText">My goal is to create a product that helps people improve themselves</p>
                                    <div className="dataRoomDashCEOBottom">
                                        <div className="dataRoomDashCEOSocbox">
                                            <a href="https://instagram.com/andrew.aeer?igshid=OGQ5ZDc2ODk2ZA=="><img src={ceoInsta} alt="" /></a>
                                            <a href="https://www.linkedin.com/in/aeer/"><img src={ceoLinked} alt="" /></a>
                                            <a href="https://twitter.com/mr_aeer"><img src={DRX} alt="" /></a>
                                            <a href="mailto:ceo@aeer-platform.com"><img src={ceoGmail} alt="" /></a>
                                        </div>
                                        <a href="https://www.linkedin.com/in/aeer/" className="dataRoomDashCEOBtn" 
                                            // onClick={() => setModalStatus(!modalStatus)}
                                            >Lets get in touch</a>
                                    </div>
                                </div>
                                <div className="dataRoomDashCEOImage"></div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className={`dataRoomModal ${modalStatus && 'active'}`}>
                <button className="dataRoomModalClose" type="button" onClick={() => setModalStatus(!modalStatus)}>
                    <img src={DataRoomModalClose} alt="" />
                </button>
                <div className="dataRoomModalBox">
                    <div className="dataRoomModalForm">
                        <div className="dataRoomModalInput">
                            <img src={DataRoomModalMail} alt="" className="dataRoomModalIcon" />
                            <label htmlFor="">Email</label>
                            <input type="text" placeholder="Please enter your email" />
                        </div>
                        <div className="dataRoomModalLine"></div>
                        <div className="dataRoomModalTextarea">
                            <img src={DataRoomModalMail} alt="" className="dataRoomModalIcon textarea" />
                            <textarea placeholder="Please enter your message"></textarea>
                        </div>
                        <button className="dataRoomModalBtn">Send message</button>
                    </div>
                </div>
            </div>
            <div className={`dataRoomModalOverlay ${modalStatus && 'active'}`} onClick={() => setModalStatus(!modalStatus)}></div>
            
        </section>
    )
}

export default memo(DataRoomDash);